import React, { useState } from 'react';
import { Link } from 'gatsby';

export const Header = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  return (
    <header className={`w-full flex items-center mx-auto h-24 mb-10 border-b border-secondary lg:justify-center lg:h-32 ${showMenu ? 'menu-open' : ''}`}>
      <nav className="w-full h-24 flex justify-between basis-full items-center lg:container lg:justify-start">
        <Link to="/">
          <div className="bg-white flex items-center mr-8">
            {' '}
            <img
              src={process.env.GATSBY_REGION === "no" ? "/no/images/bransjelosninger-logo2.png" : "/se/images/branschlosningar-logo.svg"}
              className="App-logo mx-8"
              alt="logo"
              onClick={() => setShowMenu(false)}
              height={50}
              width={365}
            />
          </div>
        </Link>
        <ul
          className={`lg:translate-x-0 transform transition-translate duration-300 menu-out flex flex-col lg:flex-row w-screen lg:w-auto z-10 fixed lg:static top-24 bottom-0 overflow-y-scroll lg:overflow-y-visible lg:h-auto text-2xl text-gray-700 bg-gray-200 lg:bg-white lg:justify-between lg:gap-12 lg:text-lg ${showMenu ? 'translate-x-0 in-menu duration-300' : 'translate-x-full '}`
          }
        >
          <li className={`border-b lg:border-0 border-gray-400 h-24 lg:h-auto flex items-center justify-center ${showMenu ? '' : ''}`} >
            <Link
              className="h-full w-full leading-24 text-center lg:hover:underline"
              to="/om-branschlosningar-se"
              onClick={() => setShowMenu(false)}
            >
              {process.env.GATSBY_REGION === "no" ? "Om Bransjeløsninger.no" : "Om Branschlösningar.se"}
            </Link>
          </li>
          <li className={`border-b lg:border-0 border-gray-400 h-24 lg:h-auto flex items-center justify-center ${showMenu ? '' : ''}`}>
            <Link
              className="h-full w-full leading-24 text-center lg:hover:underline"
              to="/publicera-er-losning"
              onClick={() => setShowMenu(false)}
            >
              {process.env.GATSBY_REGION === "no" ? "Vil du synes her?" : "Publicera en lösning"}
            </Link>
          </li>
          <li className={`group flex-col border-b lg:border-0 border-gray-400 lg:h-auto flex items-center justify-center ${showMenu ? '' : ''}`}>
            <input type="button" value="Land" className="h-24 cursor-pointer" />
            <div className="hidden w-full lg:w-auto lg:shadow-md lg:rounded-b-md lg:border-x lg:border-b lg:border-gray-200 lg:bg-gradient-to-t lg:from-gray-100 lg:to-white lg:justify-evenly lg:absolute flex-col lg:top-16 group-hover:flex group-hover:items-center group-hover:bg-gray-300">
              {(process.env.GATSBY_REGION === "se" || process.env.GATSBY_REGION === "dk") &&
                <a
                  className="w-full h-16 block px-6 leading-16 lg:hover:bg-gray-200 text-center lg:hover:underline"
                  href="http://www.bransjelosninger.com/"
                  onClick={() => setShowMenu(false)}
                  target="_blank"
                  rel="noopener"
                >
                  Norge
                </a>
              }
              {(process.env.GATSBY_REGION === "se" || process.env.GATSBY_REGION === "no") &&
                <a
                  className="w-full h-16 block px-6 leading-16 lg:hover:bg-gray-200 text-center lg:hover:underline"
                  href="http://www.branchelosninger.dk/"
                  onClick={() => setShowMenu(false)}
                  target="_blank"
                  rel="noopener"
                >
                  Danmark
                </a>
              }
              {(process.env.GATSBY_REGION === "no" || process.env.GATSBY_REGION === "dk") &&
                <a
                  className="w-full h-16 block px-6 leading-16 lg:hover:bg-gray-200 text-center lg:hover:underline"
                  href="http://www.branschlosningar.se/"
                  onClick={() => setShowMenu(false)}
                  target="_blank"
                  rel="noopener"
                >
                  Sverige
                </a>
              }

            </div>
          </li>
          <li className={`border-b lg:border-0 border-gray-400 h-24 lg:h-auto flex items-center justify-center ${showMenu ? '' : ''}`}>
            <Link
              className="h-full w-full leading-24 text-center lg:hover:underline"
              to="/kontakt"
              onClick={() => setShowMenu(false)}
            >
              Kontakt
            </Link>
          </li>
        </ul>
        <div className="lg:hidden flex items-center mx-8">
          <button
            className="outline-none mobile-menu-button"
            onClick={() => setShowMenu(!showMenu)}
          >
            <svg
              className="w-6 h-6 text-gray-500"
              x-show="!showMenu"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </nav>
    </header>
  );
};
