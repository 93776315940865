import React from 'react';
import { graphql } from "gatsby";
import { CategoryView } from '../components/category-view';
import { Header } from '../components/header';
import { Footer } from '../components/footer';
import { ScrollToTopButton } from '../components/scroll-to-top-button/scroll-to-top-button';

export const query = graphql`
query($categoryId: Int!) {
  allSolutionsJson(filter: {categories: {eq: $categoryId}}) {
    edges {
      node {
        productName
        company
        solutionId
        title
        description
        link
        additionalLinks {
          title
          url
        }
        referenceCases
        video
        image {
          name
          src {
            childImageSharp {
              gatsbyImageData(width: 126, height: 126)
            }
          }
        }
      }
    }
  }
  categoriesJson(categoryId: {eq: $categoryId}) {
    lead
  }
}`

const Category = ({ data }) => {
  const solutions = data.allSolutionsJson.edges;
  const category = data.categoriesJson;

  return (
    <>
      <Header />
      <CategoryView categoryInfo={category} solutions={solutions} />
      <Footer />
      <ScrollToTopButton />
    </>
  )
};

export default Category;