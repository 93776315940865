import React, { useState } from 'react';
import './style.css';

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";

export function ScrollToTopButton() {
  const [visible, setVisible] = useState<boolean>(false);



  const toggleVisible = () => {
    if (isBrowser) {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        setVisible(true);
      } else if (scrolled <= 300) {
        setVisible(false);
      }
    }
  };

  const scrollToTop = () => {
    if (isBrowser) {

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
        /* you can also use 'auto' behaviour
           in place of 'smooth' */
      });
    }
  };

  if (isBrowser) {
    window.addEventListener('scroll', toggleVisible);
  }

  return (
    <>
      <button
        onClick={scrollToTop}
        className={`bg-gray-300 top-link ${visible ? '' : 'hide'}`}
        id="js-top"
      >
        <img src='data:image/svg+xml;utf8,<svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -5 100 100"
        >
          <g transform="rotate(-45, 50, 50)">
            <path d="M 30 30 H 70 V 40 H 30 L 30 30" />
            <path d="M 70 30 V 70 H 60 V 30 L 70 30" />
          </g>
        </svg>'></img>
        
        <span className="screen-reader-text">Back to top</span>
      </button>
    </>
  );
}
